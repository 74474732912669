import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComeAccedereRoutingModule } from './come-accedere-routing.module';
import { ComeAccedereComponent } from './come-accedere.component';
import { QuillModule } from 'ngx-quill';
import { TranslateModule } from '@ngx-translate/core';
import { ComeAccedereFacade } from './come-accedere.facade';
import { ErrorHandlerFacade } from '../../facades';


@NgModule({
  declarations: [ComeAccedereComponent],
  imports: [
    CommonModule,
    ComeAccedereRoutingModule,
    QuillModule.forRoot(),
    TranslateModule
  ], 
  providers: [ComeAccedereFacade, ErrorHandlerFacade]
})
export class ComeAccedereModule { }
