import { Component, OnInit } from '@angular/core';
import { ShowcasePageInterface } from '../../interfaces';
import { TraduzioniVetrinaDTO } from '../../dtos';
import { TranslateService } from '@ngx-translate/core';
import { ComeAccedereFacade } from './come-accedere.facade';

@Component({
  selector: 'app-come-accedere',
  templateUrl: './come-accedere.component.html',
  styleUrls: ['./come-accedere.component.scss']
})
export class ComeAccedereComponent implements OnInit {

  public showcasePage: ShowcasePageInterface;
  public traduzioneVetrina: TraduzioniVetrinaDTO;

  constructor(private facade: ComeAccedereFacade,
    private translate: TranslateService) { }

  async ngOnInit(): Promise<void> {
    this.showcasePage = await this.facade.get();
    if (!!this.showcasePage) {
      this.traduzioneVetrina = this.getCurrentPageByLocale(this.translate.currentLang);
    }

    this.translate.onLangChange.subscribe((event: any) => {
      if (!!this.showcasePage) {
        this.traduzioneVetrina = this.getCurrentPageByLocale(event.lang);
      }
    });
  }

  getCurrentPageByLocale(locale: string): TraduzioniVetrinaDTO {
    const translate = this.showcasePage?.getPaginaVetrina().find((v) => v.traduzioni.lingua == locale)
    return translate
  }
}
