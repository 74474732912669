import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomepageRoutingModule } from './homepage-routing.module';
import { HomepageComponent } from './homepage.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';
import { ButtonLoginModule } from './components/button-login/button-login.module';
import { CarouselModule } from './components/carousel/carousel.module';
import { HomepageFacade } from './homepage.facade';
import { ErrorHandlerFacade } from '../../facades';

@NgModule({
  declarations: [HomepageComponent],
  imports: [
    CommonModule,
    HomepageRoutingModule,
    RouterModule,
    TranslateModule,
    QuillModule.forRoot(),
    ButtonLoginModule,
    CarouselModule
  ], providers: [HomepageFacade, ErrorHandlerFacade]
})
export class HomepageModule { }
