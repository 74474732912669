import { Injectable } from "@angular/core";
import { DatiIndennitaBeneficiarioDTO, CompanionDTO, FreeInstanceDTO, DatiIndennitaFamiliareDTO, FamiliareInpsInterface, FamiliareInpsDTO, DelegatoDeleganteDTO, DelegatoService, FileDTO, DatiIndennitaDelegatoDTO, FileService, FILE_TYPE, CompanionInterface, TIPOLOGIA_RICHIEDENTE } from "core-model";
import { DraftFreeInstanceInterface } from "core-model";
import { DraftFreeInstanceModel, CategoriaDisabilitaModel, GradoDisabilitaModel, FamiliareInpsModel, RequisitoDisabilitaModel } from "core-model";
import {
    AccompagnatoreService, BeneficiarioService, CategoriaDisabilitaService, FamiliareService, FreeInstanceService,
    GradoDisabilitaService, RequisitoDisabilitaService
} from "core-model";
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorFacade } from "./error.facade";
import { AuthFacade } from "./auth.facade";
import * as FileSaver from 'file-saver';
import { TranslateService } from "@ngx-translate/core";
import { catchError, filter, finalize } from "rxjs";
import { HttpEventType } from "@angular/common/http";


@Injectable({
    providedIn: 'root'
})
export class RichiestaGratuitaFacade {
    constructor(
        private accompagnatoreService: AccompagnatoreService,
        private authFacade: AuthFacade,
        private beneficiarioService: BeneficiarioService,
        private categoriaDisabilitaService: CategoriaDisabilitaService,
        private delegatoService: DelegatoService,
        private fileService: FileService,
        private gradoDisabilitaService: GradoDisabilitaService,
        private errorFacade: ErrorFacade,
        private familiareService: FamiliareService,
        private freeInstanceService: FreeInstanceService,
        private requisitoDisabilitaService: RequisitoDisabilitaService,
        private spinner: NgxSpinnerService,
        private translateService: TranslateService) { }

    /**
    * Invia i dati di anagrafica del beneficiario da parte del delegante
    * @param { DelegatoDeleganteDTO  } datiAnagrafica
    * @returns { Promise<DraftFreeInstanceInterface> }
    */
    async addAnagraficaBeneficiarioDelegante(datiIndennita: DelegatoDeleganteDTO): Promise<DraftFreeInstanceInterface> {
        try {
            this.spinner.show();
            const dto = await this.delegatoService.aggiungiDelega(datiIndennita);
            return new DraftFreeInstanceModel(dto);
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
    * Invia i dati di indennità del beneficiario, effettua il controllo dei dati inseriti
    * @param { DatiIndennitaBeneficiarioDTO } datiIndennita
    * @returns { Promise<DraftFreeInstanceInterface> }
    */
    async addDatiIndennitaBeneficiario(datiIndennita: DatiIndennitaBeneficiarioDTO): Promise<DraftFreeInstanceInterface> {
        try {
            this.spinner.show();
            const dto: FreeInstanceDTO = await this.beneficiarioService.aggiungi(datiIndennita);
            return new DraftFreeInstanceModel(dto);
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
    * Invia i dati di indennità del beneficiario
    * @param { DatiIndennitaDelegatoDTO } datiIndennita
    * @returns { Promise<DraftFreeInstanceInterface> }
    */
    async addDatiIndennitaDelegato(datiIndennita: DatiIndennitaDelegatoDTO): Promise<DraftFreeInstanceInterface> {
        try {
            this.spinner.show();
            // const dto: FreeInstanceDTO = await this.delegatoService.aggiungiGratuita(datiIndennita);
            await this.delegatoService.aggiungiGratuita(datiIndennita);
            const dto = await this.freeInstanceService.getById(datiIndennita.idGratuita);
            return new DraftFreeInstanceModel(dto);
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
    * Invia i dati di indennità del beneficiario da parte di familiare, effettua il controllo dei dati inseriti
    * @param { DatiIndennitaFamiliareDTO } datiIndennita
    * @returns { Promise<DraftFreeInstanceInterface> }
    */
    async addDatiIndennitaFamiliare(datiIndennita: DatiIndennitaFamiliareDTO): Promise<DraftFreeInstanceInterface> {
        try {
            this.spinner.show();
            const dto: FreeInstanceDTO = await this.familiareService.aggiungi(datiIndennita);
            return new DraftFreeInstanceModel(dto);
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
     * Invia i dati dell'accompagnatore
     *
     * @param { CompanionDTO } accompagnatore
     * @returns { Promise<DraftFreeInstanceInterface> }
     */
    async aggiungiAccompagnatore(accompagnatore: CompanionDTO): Promise<DraftFreeInstanceInterface> {
        try {
            this.spinner.show();
            await this.accompagnatoreService.aggiungi(accompagnatore, 'INSERT');
            const dto = await this.freeInstanceService.getById(accompagnatore.idGratuita);
            return new DraftFreeInstanceModel(dto);
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
     * Elimina l'accompagnatore
     *
     * @param { CompanionDTO } accompagnatore
     * @param { DraftFreeInstanceInterface } freeInstance
     * @returns { Promise<DraftFreeInstanceInterface> }
     */
    async deleteAccompagnatore(accompagnatore: CompanionInterface, freeInstance: DraftFreeInstanceInterface): Promise<DraftFreeInstanceInterface> {
        try {
            this.spinner.show();
            await this.accompagnatoreService.elimina(accompagnatore.getId())
            const dto = await this.freeInstanceService.getById(freeInstance.getId());
            return new DraftFreeInstanceModel(dto);
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
     * Download documento delega firmata
     *
     * @param { DraftFreeInstanceInterface } freeInstance
     * @return { Promise<any> }
     */
    async downloadDocumentoFirmato(freeInstance: DraftFreeInstanceInterface): Promise<any> {
        try {
            this.spinner.show();
            const file = await this.fileService.download(freeInstance.getId(), freeInstance.getIdFileRichiesta(), FILE_TYPE.FREEINSTANCE);
            const blob = new Blob([file], { type: 'application/pdf' });
            FileSaver.saveAs(blob, freeInstance.getNomeFileRichiesta());
            return true;
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
     *
     *
     * @param { DraftFreeInstanceInterface } freeInstance
     * @return { Promise<any> }
     */
    async downloadDocumentoDelegaFirmato(freeInstance: DraftFreeInstanceInterface): Promise<any> {
        try {
            this.spinner.show();
            const file = await this.fileService.download(freeInstance.getId(), freeInstance.getIdFileRichiesta(), FILE_TYPE.DELEGATION);
            const blob = new Blob([file], { type: 'application/pdf' });
            FileSaver.saveAs(blob, freeInstance.getNomeFileRichiesta());
            return true;
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
     * Upload il documento firmato
     *
     * @param { number } idFreeInstance
     * @param { FileDTO } fileDto
     * @return { any }
     */
    uploadDocumento(idFreeInstance: number, fileDto: FileDTO): any {
        // try {
        const handleError = (error) => {
            this.spinner.hide();
            this.errorFacade.handle(error, null);
        };
        this.spinner.show();
        return this.fileService.upload(idFreeInstance, fileDto, FILE_TYPE.FREEINSTANCE)
            .pipe(filter((event: { type: HttpEventType }) => event.type === HttpEventType.Response),
                catchError((error) => {
                    handleError(error);
                    throw error;
                }),
                finalize(() => {
                    this.spinner.hide();
                }));
        // } catch (error) {
        //     this.errorFacade.handle(error, null);
        // }
        // finally {
        //     this.spinner.hide();
        // }
        // return null;
    }

    /**
     * Upload il documento di delega firmato
     *
     * @param { number } idFreeInstance
     * @param { FileDTO } dichiarazione
     * @param { FileDTO } datiCartaIdentita
     * @return { any }
     */
    uploadDocumentoDelega(idFreeInstance: number, dichiarazione: FileDTO, datiCartaIdentita: FileDTO): any {
        // try {
        const handleError = (error) => {
            this.spinner.hide();
            this.errorFacade.handle(error, null);
        };
        this.spinner.show();
        dichiarazione.tipologiaFile = FILE_TYPE.DELEGATION;
        datiCartaIdentita.tipologiaFile = FILE_TYPE.IDENTITY_CARD;
        return this.fileService.uploadDelega(idFreeInstance, dichiarazione, datiCartaIdentita)
            .pipe(filter((event: { type: HttpEventType }) => event.type === HttpEventType.Response),
                catchError((error) => {
                    handleError(error);
                    throw error;
                }),
                finalize(() => {
                    this.spinner.hide();
                }));
        // } catch (error) {
        //     this.spinner.hide();
        //     this.errorFacade.handle(error, null);
        // }
    }


    /**
     * Ottiene la lista delle categorie di invalidità
     * @returns { CategoriaDisabilitaModel[] }
     */
    async getAllCategoriaDisabilita(): Promise<CategoriaDisabilitaModel[]> {
        try {
            this.spinner.show();
            const dtos = await this.categoriaDisabilitaService.get();
            return dtos.map((dto) => new CategoriaDisabilitaModel(dto));
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
     * Ottiene la lista dei gradi di invalidità
     * @returns { GradoDisabilitaModel[] }
     */
    async getAllGradoDisabilita(): Promise<GradoDisabilitaModel[]> {
        try {
            this.spinner.show();
            const dtos = await this.gradoDisabilitaService.get();
            return dtos.map((dto) => new GradoDisabilitaModel(dto));
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
     * Ottiene la lista dei requisiti di invalidità
     * @returns { RequisitoDisabilitaModel[] }
     */
    async getAllRequisitoDisabilita(): Promise<RequisitoDisabilitaModel[]> {
        try {
            try {
                this.spinner.show();
                const dtos = await this.requisitoDisabilitaService.get();
                return dtos.map((dto) => new RequisitoDisabilitaModel(dto));
            } catch (error) {
                this.errorFacade.handle(error, null);
            } finally {
                this.spinner.hide();
            }
            return null;
        } catch (error) {
            this.errorFacade.handle(error);
        }
        return null;
    }

    /**
     * Ottiene la gratuità
     * @param { number } id
     * @returns { Promise<DraftFreeInstanceInterface> }
     */
    async getFreeInstanceById(id: number): Promise<DraftFreeInstanceInterface> {
        try {
            try {
                this.spinner.show();
                const dto = await this.freeInstanceService.getById(id);
                return new DraftFreeInstanceModel(dto);
            } catch (error) {
                this.errorFacade.handle(error, null);
            } finally {
                this.spinner.hide();
            }
            return null;
        } catch (error) {
            this.errorFacade.handle(error);
        }
        return null;
    }

    /**
     * Genera il documento di richiesta gratuita e scarica il file PDF generato
     *
     * @param { DraftFreeInstanceInterface } freeInstance
     * @return { Promise<any> }
     */
    async generaDocumento(freeInstance: DraftFreeInstanceInterface): Promise<any> {
        try {
            this.spinner.show();
            const file = await this.fileService.genera(freeInstance.getId(), FILE_TYPE.FREEINSTANCE);
            const blob = new Blob([file], { type: 'application/pdf' });
            let name = freeInstance.getRichiedenteGratuita().getBeneficiario().getAnagrafica().getCognome() + '_' + freeInstance.getRichiedenteGratuita().getBeneficiario().getAnagrafica().getNome();
            name = name.replace(/[^\w\s]/gi, '').replace(/\s+/g, '');
            this.translateService.get('FILENAME.RICHIESTA', { name: name }).subscribe((translated: string) => {
                const fileName = translated + '.pdf';
                FileSaver.saveAs(blob, fileName);
            });
            return true;
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
     * Genera il documento di delega e scarica il file PDF generato
     *
     * @param { DraftFreeInstanceInterface } freeInstance
     * @return { Promise<any> }
     */
    async generaDocumentoDelega(freeInstance: DraftFreeInstanceInterface): Promise<any> {
        try {
            this.spinner.show();
            const file = await this.fileService.genera(freeInstance.getId(), FILE_TYPE.DELEGATION);
            const blob = new Blob([file], { type: 'application/pdf' });
            let name = freeInstance.getRichiedenteGratuita().getBeneficiario().getAnagrafica().getCognome() + '_' + freeInstance.getRichiedenteGratuita().getBeneficiario().getAnagrafica().getNome();
            name = name.replace(/[^\w\s]/gi, '').replace(/\s+/g, '');
            this.translateService.get('FILENAME.DELEGA', { name: name }).subscribe((translated: string) => {
                const fileName = translated + '.pdf';
                FileSaver.saveAs(blob, fileName);
            });
            return true;
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
     * Ottiene il nucleo familiare
     *
     * @returns { Promise<FamiliareInpsInterface[]> }
     */
    async getNucleoFamiliare(): Promise<FamiliareInpsInterface[]> {
        try {
            this.spinner.show();
            const fiscalCode = this.authFacade.getCurretUser().getAnagrafica().getCodiceFiscale();
            const dtos: Array<FamiliareInpsDTO> = await this.familiareService.getNucleoFamiliare(fiscalCode);
            return dtos.map((dto) => new FamiliareInpsModel(dto));
        } catch (error) {
            console.error(error);
            this.errorFacade.handle(error, null, 'family');
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
     * Verifica l'isee del beneficiario e che il beneficiario associato al codice fiscale non abbia una gratuità attiva o sospesa
     * @param tipologiaRichiedente
     * @param fiscalCode
     * @returns
     */
    async verificaBeneficiario(tipologiaRichiedente: TIPOLOGIA_RICHIEDENTE, fiscalCode?: string): Promise<boolean> {
        try {
            this.spinner.show();
            if (tipologiaRichiedente == TIPOLOGIA_RICHIEDENTE.BENEFICIARIO) {
                fiscalCode = this.authFacade.getCurretUser().getAnagrafica().getCodiceFiscale();
            }
            const resp1 = await this.beneficiarioService.verifica(fiscalCode);
            if(tipologiaRichiedente != TIPOLOGIA_RICHIEDENTE.TERZO_DELEGATO){
              const resp2 = await this.beneficiarioService.verificaIsee(fiscalCode);
              return resp1 && resp2;
            }
            return resp1;
        } catch (error) {
            console.error(error);
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return false;
    }
}
