import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard, personalAreaGuard, activeFakeLoginGuard } from '../shared/guards/';


const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./sections/area-personale/area-personale.module').then(mod => mod.AreaPersonaleModule),
    canActivate: [authGuard]
  },
  {
    path: 'area-personale',
    loadChildren: () => import('./sections').then(m => m.AreaPersonaleModule),
    canActivate: [personalAreaGuard]
  },
  {
    path: 'contatti',
    loadChildren: () => import('core-model').then(m => m.ContattiModule)
  },
  {
    path: 'admin-login',
    loadChildren: () => import('core-model').then(m => m.AdminLoginModule)
  },
  {
    path: 'errore-generico',
    loadChildren: () => import('core-model').then(m => m.ErroreGenericoModule)
  },
  {
    path: 'fake-login',
    loadChildren: () => import('./sections').then(m => m.FakeLoginModule),
    canActivate: [activeFakeLoginGuard]
  },
  {
    path: 'frontoffice',
    loadChildren: () => import('./sections/area-personale/area-personale.module').then(mod => mod.AreaPersonaleModule),
    canActivate: [authGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('core-model').then(m => m.HomepageModule),
  },
  {
    path: 'come-accedere',
    loadChildren: () => import('core-model').then(m => m.ComeAccedereModule),
  },
  {
    path: 'news',
    loadChildren: () => import('core-model').then(m => m.NewsModule)
  },
  {
    path: '', redirectTo: '/home', pathMatch: 'full'
  },
  {
    path: '**', redirectTo: '/home', pathMatch: 'full'
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
