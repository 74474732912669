import { Component, OnInit } from '@angular/core';
import { CurrentUserObserver, ShowcasePageInterface, UtenteInterface } from 'core-model';
import { Subscription } from 'rxjs';
import { environment } from 'projects/app-front-office/src/environments/environment';

@Component({
  selector: 'app-header-navbar',
  templateUrl: './header-navbar.component.html',
  styleUrls: ['./header-navbar.component.scss']
})
export class HeaderNavbarComponent implements OnInit {
  public toggle = false;

  public currentUser: UtenteInterface;
  public currentUser$: Subscription;
  public environment = environment;

  public showCasePage: ShowcasePageInterface[] = null;

  constructor(private currentUserObserver: CurrentUserObserver) { }

  ngOnInit(): void {
    this.currentUser$ = this.currentUserObserver.on().subscribe((currentUser) => {
      this.currentUser = currentUser;
    });
  }

  toggleMenu() {
    this.toggle = !this.toggle;
  }
}
