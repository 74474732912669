import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateShowcasePageObserver {

  private subject: Subject<boolean> = new Subject<boolean>();

  constructor() { 
    console.log('UpdateShowcasePageObserver')
  }

  on(): Observable<boolean> {
    return this.subject.asObservable();
  }

  notify() {
    console.log('notify');
    this.subject.next(true);
  }
}
