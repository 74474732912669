import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ImageService } from "../../../services/image.service";
import { NewsService, TagsService } from "../../../services";
import { ImageNewsDTO, SubmitNewsDTO,  } from "../../../dtos";
import { NewsInterface, PaginationInterface, TagsInterface } from "../../../interfaces";
import { NewsModel, NewsPaginationModel, TagsModel } from "../../../models";
import { ErrorHandlerFacade } from "../../../facades";

@Injectable({
    providedIn: 'root'
})
export class Newsfacade {

    constructor(private spinner: NgxSpinnerService,
                private errorFacade: ErrorHandlerFacade,
                private newsService: NewsService,
                private tagsService: TagsService,
                private imageService: ImageService) { }
    async aggiungi(news: SubmitNewsDTO): Promise<NewsInterface> {
        try {
            this.spinner.show();
            const dto = await this.newsService.aggiungi(news);

            return new NewsModel(dto);
        } catch (error) {
            this.errorFacade.handle(error, null);
            throw null;
        } finally {
            this.spinner.hide();
        }
    }

    async aggiorna(news: SubmitNewsDTO, id: number): Promise<NewsInterface> {
        try {
            this.spinner.show();
            const dto = await this.newsService.aggiorna(news, id);
            return new NewsModel(dto);
        } catch (error) {
            this.errorFacade.handle(error, null);
            throw null;
        } finally {
            this.spinner.hide();
        }
    }

    async ottieni(id: number): Promise<NewsInterface> {
        try {
            this.spinner.show();
            const dto = await this.newsService.ottieni(id);
            return new NewsModel(dto);
        } catch (error) {
            this.errorFacade.handle(error, null);
            return null
        } finally {
            this.spinner.hide();
        }
    }

    async ottieniFull(id: number): Promise<NewsInterface> {
        try {
            this.spinner.show();
            const dto = await this.newsService.ottieni(id);
            const copertina = await this.imageService.ottieniCoprtina(dto.id);
            const immaginiCarousel = await this.imageService.ottieniImmaginiCarousel(dto.id);
            dto.copertina = copertina;
            dto.immagini = immaginiCarousel;
            return new NewsModel(dto);
        } catch (error) {
            this.errorFacade.handle(error, null);
            return null
        } finally {
            this.spinner.hide();
        }
    }

    async rimuovi(id: number): Promise<NewsInterface> {
        try {
            this.spinner.show();
            const dto = await this.newsService.rimuovi(id);
            return new NewsModel(dto);
        } catch (error) {
            this.errorFacade.handle(error, null);
            return null
        } finally {
            this.spinner.hide();
        }
    }

    async ricercaTags(label: string, spinnerShow: boolean = true): Promise<TagsInterface[]> {
        try {
            if (spinnerShow) {
                this.spinner.show();
            }
            const dto = await this.tagsService.ricerca(label);
            return dto.map(t => new TagsModel(t))
        } catch (error) {
            this.errorFacade.handle(error, null);
            return null
        } finally {
            if (spinnerShow) {
                this.spinner.hide();
            }
        }
    }

    async ottieniTags(id: number, spinnerShow: boolean = true): Promise<TagsInterface[]> {
        try {
            if (spinnerShow) {
                this.spinner.show();
            }
            const dto = await this.tagsService.ottieni(id);
            return dto.map(t => new TagsModel(t))
        } catch (error) {
            this.errorFacade.handle(error, null);
            return null
        } finally {
            if (spinnerShow) {
                this.spinner.hide();
            }
        }
    }

    async ottieniCopertina(id: number): Promise<ImageNewsDTO> {
        try {
            this.spinner.show();
            const dto = await this.imageService.ottieniCoprtina(id);
            return dto
        } catch (error) {
            this.errorFacade.handle(error, null);
            return null
        } finally {
            this.spinner.hide();
        }
    }

    async ottieniImmaginiCarousel(id: number): Promise<ImageNewsDTO[]> {
        try {
            this.spinner.show();
            const dto = await this.imageService.ottieniImmaginiCarousel(id);
            return dto
        } catch (error) {
            this.errorFacade.handle(error, null);
            return null
        } finally {
            this.spinner.hide();
        }
    }

    async lista(page: number = 0, pageSize: number = 10): Promise<PaginationInterface> {
        try {
            this.spinner.show();
            const dtos = await this.newsService.lista(page, pageSize);
            return new NewsPaginationModel(dtos)
        } catch (error) {
            this.errorFacade.handle(error, null);
            return null
        } finally {
            this.spinner.hide();
        }
    }

    async listaEditor(page: number = 0, pageSize: number = 10): Promise<PaginationInterface> {
      try {
          this.spinner.show();
          const dtos = await this.newsService.listaEditor(page, pageSize);
          return new NewsPaginationModel(dtos)
      } catch (error) {
          this.errorFacade.handle(error, null);
          return null
      } finally {
          this.spinner.hide();
      }
  }


}
