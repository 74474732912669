import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ErrorObserverService } from "../singletons";

@Injectable({
    providedIn: 'any'
})
export class ErrorHandlerFacade {
    constructor(
        private errorObserver: ErrorObserverService,
        private router: Router) { }

    /**
     * Gestore dell'errore
     * @returns
     */
    public handle(httpError: HttpErrorResponse | any, title?: string, name = 'MAIN') {
        // GESTIONE INTERNA ERRORI
        if (httpError.status >= 0 && httpError.status < 100) {
            switch (httpError?.status) {
                case 1:
                    // Il beneficiario ha già una gratuità attiva
                    this.errorObserver.send({ name: name, title: title, message: httpError?.error });
                    this.router.navigate(['/', 'area-personale']);
                    break;
                case 2:
                    // Il cambio di richiedente è già stato attivato
                    this.errorObserver.send({ name: name, title: title, message: httpError?.error });
                    break;
                case 3:
                  this.errorObserver.send({ name: name, title: title, message: !!httpError?.error ? httpError?.error : "ERROR.DOWNLOAD"});
                  break;
                default:
                    this.errorObserver.send({ name: name, title: title, message: httpError?.error });
                    break;
            }
            return;
        }
        if (httpError.status >= 400 && httpError.status < 500) {
            const error = httpError?.error;
            console.warn('error', error);
            const msg = Array.isArray(error) ? error?.join('\n') : (typeof error === 'string') ? error : null;
            switch (httpError?.status) {
                case 400: // Bad request
                    this.errorObserver.send({ name: name, title: title, message: msg || 'ERROR.BAD_REQUEST' });
                    break;
                case 403: // Forbiden
                    this.errorObserver.send({ name: name, title: title, message: 'ERROR.FORBIDDEN' });
                    break;
                case 404: // Not found
                    this.errorObserver.send({ name: name, title: title, message: !!msg ? msg : 'ERROR.NOT_FOUND' });
                    break;
                case 409: // Conflict
                    this.errorObserver.send({ name: name, title: title, message: !!msg ? msg : 'ERROR.CONFLICT' });
                    break;
                default:
                    this.errorObserver.send({ name: name, title: title, message: msg });
            }
            return;
        }
        if (httpError?.status >= 500 && httpError?.status < 600) {
            const msg = Array.isArray(httpError?.error) ? httpError?.error?.join('\n') : httpError?.error;
            switch (httpError?.status) {
                case 503: // External service not available
                    this.errorObserver.send({ title: title, message: msg || 'ERROR.EXTERNAL_SERVICE_NOT_AVAILABLE' });
                    break;
                default:
                    this.router.navigate(['/', 'errore-generico']);
            }
            return;
        }
        this.router.navigate(['/', 'errore-generico']);
    }
}
