import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContattiRoutingModule } from './contatti-routing.module';
import { ContattiComponent } from './contatti.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ContactFacade } from './contact.facade';
import { ErrorHandlerFacade } from '../../facades';


@NgModule({
  declarations: [ContattiComponent],
  imports: [
    CommonModule,
    ContattiRoutingModule,
    TranslateModule,
    ReactiveFormsModule
  ],
  providers: [
    ContactFacade, ErrorHandlerFacade
  ]
})
export class ContattiModule { }
