import { Injectable, inject } from '@angular/core';
import { ShowcasePageInterface } from '../../interfaces';
import { ShowcasePage } from '../../models';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShowcasePageService } from '../../services';
import { ShowCasePageEnum } from '../../constants';
import { ErrorHandlerFacade } from '../../facades';

@Injectable({
  providedIn: 'any'
})
export class HomepageFacade {
  private showcasePageService = inject(ShowcasePageService);

  constructor(private spinner: NgxSpinnerService,
    private errorFacade: ErrorHandlerFacade) { }

  async get(): Promise<ShowcasePageInterface> {
    try {
      this.spinner.show();
      const list = await this.showcasePageService.lista();
      const pages = list.map((dto) => new ShowcasePage(dto));
      const ID = pages.find((p) => p.getNavbarLabel() == ShowCasePageEnum['/home'])?.getId();
      if (!!ID) {
        const dto = await this.showcasePageService.ottieni(ID);
        return new ShowcasePage(dto);
      } else {
        return null;
      }

    } catch (error) {
      this.errorFacade.handle(error, null);
      return null;
    } finally {
      this.spinner.hide();
    }
  }
}
